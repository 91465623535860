import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

function getBase64ImageFromURL (url) {
  return new Promise((resolve, reject) => {
    var img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.onload = () => {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      var dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
    img.onerror = error => {
      reject(error)
    }
    img.src = url
  })
}
const exportPdfDetails = async (reportTitle, data, vm, item, cityCorp, ward, occupation, baseUrl) => {
  try {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        if (i18n.locale === 'bn') {
          pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const pdfContent = []
        const allRows = []
        pdfContent.push([
          { text: '', style: 'empty_space' },
          { image: await getBase64ImageFromURL(baseUrl + 'download-attachment?file=storage/' + item.photo), width: 100, height: 100, style: 'search', rowSpan: 5, absolutePosition: { x: 670, y: 50 } },
          { text: vm.$i18n.locale === 'en' ? item.beneficiary_name : item.beneficiary_name_bn, style: 'td', alignment: 'left' }
        ])
        allRows.push([
          { text: '', style: 'td', alignment: 'center' },
          { text: '', style: 'td', alignment: 'left' }
        ])
        allRows.push([
          { text: i18n.locale === 'bn' ? cityCorp.text_bn : cityCorp.text_en, style: 'thh', alignment: 'center', absolutePosition: { x: -110, y: 130 } },
          { text: vm.$t('tcbconfiguration.nid_un_one'), style: 'th', alignment: 'left' }
        ])
        allRows.push([
          { text: i18n.locale === 'bn' ? ward.text_bn : ward.text_en, style: 'tdd', alignment: 'center', absolutePosition: { x: -110, y: 150 } },
          { text: item.nid, style: 'tdunnewtwo', alignment: 'left' }
        ])
        allRows.push([
          { text: '', style: 'thh', alignment: 'center', absolutePosition: { x: -110, y: 130 } },
          { text: vm.$t('tcbconfiguration.card_no_un'), style: 'th', alignment: 'left' }
        ])
        allRows.push([
          { text: i18n.locale === 'bn' ? item.pre_address_bn : item.pre_address_bn, style: 'tdd', alignment: 'center', absolutePosition: { x: -110, y: 150 } },
          { text: item.card_no, style: 'tdunnewthree', alignment: 'left' }
        ])
        allRows.push([
          { text: '', style: 'td', alignment: 'center' },
          { text: vm.$t('globalTrans.occupation'), style: 'thunnew', alignment: 'left' }
        ])
        allRows.push([
          { text: '', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'en' ? occupation.text_en : occupation.text_bn, style: 'tdnew', alignment: 'left' }
        ])
        allRows.push([
          { text: '', style: 'td', alignment: 'center' },
          { text: vm.$t('tcbconfiguration.mobile'), style: 'thunnewtwo', alignment: 'left' }
        ])
        allRows.push([
          { text: '', style: 'td', alignment: 'center' },
          { text: item.mobile, style: 'tdnewtwo', alignment: 'left' }
        ])
        allRows.push([
          { text: '', style: 'td', alignment: 'center' },
          { text: '', style: 'td', alignment: 'left' }
        ])
        pdfContent.push({
            table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: allRows
            },
            layout: 'noBorders'
        })
        var docDefinition = {
        content: pdfContent,
        pageSize: {
          width: 350.28,
          height: 265
        },
        pageOrientation: 'landscape',
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 8 : 7,
            margin: [-16, -5, 3, 3]
          },
          td: {
            fontSize: 10,
            margin: [-16, -5, 3, 3],
            bold: true
          },
          thh: {
            fontSize: (i18n.locale === 'bn') ? 8 : 7,
            margin: [-16, 15, 3, 3]
          },
          tdd: {
            fontSize: 8,
            margin: [-16, -2, 3, 3],
            bold: true
          },
          thun: {
            fontSize: (i18n.locale === 'bn') ? 8 : 7,
            margin: [-16, -8, 3, 3]
          },
          tdunnewthree: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [-16, -16, 3, 3],
            bold: true
          },
          thunnew: {
            fontSize: (i18n.locale === 'bn') ? 8 : 7,
            margin: [-16, 0, 3, 3]
          },
          thunnewtwo: {
            fontSize: (i18n.locale === 'bn') ? 8 : 7,
            margin: [-16, 0, 3, 3]
          },
          tdun: {
            fontSize: 8,
            margin: [-16, -12, 3, 3],
            bold: true
          },
          tdunnewtwo: {
            fontSize: 10,
            margin: [-16, -16, 3, 3],
            bold: true
          },
          tdnew: {
            fontSize: (i18n.locale === 'bn') ? 8 : 10,
            margin: [-16, (i18n.locale === 'bn') ? -2 : -6, 3, 3],
            bold: true
          },
          tdnewtwo: {
            fontSize: (i18n.locale === 'bn') ? 8 : 10,
            margin: [-16, (i18n.locale === 'bn') ? -6 : -6, 3, 3],
            bold: true
          },
          empty_space: {
            margin: [20, -8, 3, 3]
          },
          empty_spaceTwo: {
            margin: [270, -23, 3, 3]
          },
          empty_spaceThree: {
            margin: [123, 10, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          img_logo: {
            margin: [20, 0, 0, 0]
          },
          img_logo_two: {
            margin: [25, 0, 0, 0]
          },
          reportHeading: {
            fontSize: 13,
            margin: [0, 10, 0, 0]
          },
          headerTwo: {
            fontSize: 12,
            margin: [0, -10, 0, 0]
          },
          headerThree: {
            fontSize: 9,
            margin: [0, 1, 0, 0]
          },
          giveUnderline: {
            decoration: 'underline',
            margin: [0, 10, 0, 0]
          },
          tableHeader: {
            alignment: 'center'
          },
          tableHeaderTwo: {
            fontSize: 9,
            alignment: 'center',
            margin: [0, 10, 0, 0]
          },
          tableHeaderThree: {
            alignment: 'left',
            margin: [0, 0, 0, 0]
          },
          tableHeaderFour: {
            alignment: 'left',
            margin: [0, 50, 0, 0]
          },
          tableHeaderFive: {
            alignment: 'left',
            bold: true
          },
          tableExampleGrid: {
            margin: [0, 5, 5, 0]
          },
          tableHeaderSix: {
            fontSize: 15,
            margin: [0, 8, 8, 0]
          },
          tableHeaderSeven: {
            fontSize: 10,
            margin: [0, 0, 0, 0]
          },
          tableHeaderEight: {
            fontSize: 10,
            margin: [20, 0, 0, 0]
          },
          leftalign: {
            alignment: 'left'
          },
          tablecell: {
            fontSize: 7,
            alignment: 'center'
          },
          tablecellTwo: {
            fontSize: 15,
            alignment: 'center'
          },
          marginForUse: {
            margin: [0, 20, 0, 0]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
